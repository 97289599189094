@font-face {
  font-family: 'Bodoni Ornaments';
  src: url('../public/BodoniOrnaments.ttf');
}

@font-face {
  font-family: 'PP Sans';
  src: url('../public/ppsimplesans.woff');
}

@font-face {
  font-family: 'Steedle';
  src: url('../public/steedle.ttf');
}


* {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  line-height: 150%;
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}