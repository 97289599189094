.main::-webkit-scrollbar {
  display: none;
}

.main {
  width: calc(100vw);
  height: calc(100vh - 20px);
  overflow-x: scroll;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.navigationSection {
  width: 440px;
  height: 440px;
  position: absolute;
  left: -80px;
  top: -20px;
  -webkit-font-smoothing: antialiased;
}

.mainInfo {
  margin-left: 370px;
  padding: 20px 20px 20px 0;
  color: #cca018;
  font-size: 19px;
  font-family: 'PP Sans';
  columns: auto 300px;
  max-height: 90vh;
  column-gap: 60px;
  margin-right: 100px;
  width: 400px;

  p {
    margin-bottom: 19px;
  }

  img {
    display: block;
  }

  a {
    border-bottom: 2px dotted;

    &::selection {
      background: #FF6817;
      color: white;
    }

    &:hover {
      border-bottom: 4px solid;
      border-image: url("../../../public/border-gold.png") 100% 2 repeat;
    }
  }



  h2 {
    padding-left: 19px;
    font-size: 26px;
    text-decoration: underline;
    margin-bottom: 19px;
    font-weight: normal;
  }

  ul {

    list-style-type: none;

    li {
      // margin-bottom: 9.5px;
      text-indent: -5px;

      &::before {
        content: "— ";
        text-indent: -5px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.languageMenu {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  width: calc(100% - 40px);
  justify-content: space-between;

  .returnButton {
    background: white;
    display: none;
    font-family: 'PP Sans';
    font-size: 19px;
    text-wrap: nowrap;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 10px;
      height: 15px;
    }
  }

}

@media screen and (max-width: 800px) {
  .main {
    height: 100%;
  }

  .navigationSection {
    left: 0;
    height: auto;
    top: 0px;
    width: 100%;
    position: relative;
  }

  .mainInfo {
    margin-left: 0;
    columns: 1;
    max-height: none;
    column-gap: 0;
    margin-right: 0;
    padding: 20px;
    padding-top: 70px;
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 600px) {
  .mainInfo {
    padding: 15px;
    font-size: 18px;
    padding-top: 65px;

    img {
      margin-bottom: 20px;
    }
  }
}