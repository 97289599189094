.mobileNavbar {
  font-family: 'Steedle';
  font-size: 40px;
  line-height: 100%;
  width: 100%;
  position: fixed;
  background: white;

}

.navButton {
  width: calc(100% - 30px);
  border-bottom: 5px solid;
  border-image: url("../../../public/border8.png") 100% 1 repeat;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button{
    background: none;
    border: none;
    font-weight: normal;
    font-family: inherit;
    font-size: inherit;
    height: 50px;
    cursor: pointer;
    margin: 0;
    text-align-last: left;
    padding: 0;
    color: black;
    -webkit-text-stroke-width: 1.4px;
   
  }
}

.menu {
  padding-top: 10px;
  display: none;
  flex-direction: column;
  padding-left: 15px;
  list-style: none;
  -webkit-text-stroke-width: 1.4px;
  border-bottom: 5px solid;
  border-image: url("../../../public/border8.png") 100% 1 repeat;
  li{
    height: 25px;
    margin-bottom: 20px;
    a {
      height: 100%;
      padding-bottom: 5px;
      display: inline-block;
    }
  }
}

a.nytky.active {
  border-bottom: 6px solid;
  border-image: url("../../../public/border8.png") 100% 1 repeat;
  margin-bottom: -6px;
}

a.about {
  color: #FF6817;
}

a.about.active {
  border-bottom: 6px solid;
  border-image: url("../../../public/border-orange.png") 100% 1 repeat;
  // margin-bottom: -6px;
}

a.events {
  color: #FF2EA4;
}

a.events.active {
  border-bottom: 6px solid;
  border-image: url("../../../public/border-pink.png") 100% 1 repeat;
  margin-bottom: -6px;
}

 a.collaboration {
   color: #c18a00;
 }

 a.collaboration.active {
   border-bottom: 6px solid;
   border-image: url("../../../public/border-gold.png") 100% 1 repeat;
   margin-bottom: -6px;
 }

a.embroideryCircles {
  color: #9F2120;
}

 a.embroideryCircles.active {
   border-bottom: 6px solid;
   border-image: url("../../../public/border-red.png") 100% 1 repeat;
   margin-bottom: -6px;

   
 }